import React from 'react';
import Header from './Header/Header';
import Home from './Home/Home';

const Portfolio = () => {
    return (
        <>
            <Header/>

            <main className='main'>
                <Home/>
            </main>
        </>
    )
}

export default Portfolio