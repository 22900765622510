import React from 'react';

const Social = () => {
  return (
    <div className="home__social">
        <div className="home__social-icon">
          <i class="uil uil-apple-alt"/>
        </div>

        <div className="home__social-icon">
          <i class="uil uil-youtube"/>
        </div>

        <div className="home__social-icon">
          <i class="uil uil-arrow"/>
        </div>

        <div className="home__social-icon">
          <i class="uil uil-adobe"/>
        </div>

        <div className="home__social-icon">
          <i class="uil uil-windows"/>
        </div>
    </div>
  )
}

export default Social